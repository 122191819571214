import Api from './api';
import { ServerResponse } from '@/models/response';
import { UserRegistrationRequest, UserLoginRequest, User } from '@/models/user';

export default class AuthApi extends Api {
  public register(email: string, username: string, password: string): Promise<ServerResponse<User>> {
    const url = '/api/auth/register';

    return this.post<UserRegistrationRequest, ServerResponse<User>>(url, { email, username, password }).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public login(email: string, password: string): Promise<ServerResponse<User>> {
    const url = '/api/auth/login';

    return this.post<UserLoginRequest, ServerResponse<User>>(url, { email, password }).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public logout(): Promise<ServerResponse<null>> {
    const url = '/api/auth/logout';

    return this.post<void, ServerResponse<null>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }
}
