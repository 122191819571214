/**
 * Order is important! (permissions descending)
 */
enum EnumRole {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  USER = 'USER',
}

interface BaseUser {
  email: string;
}

interface UserRegistrationRequest extends BaseUser {
  username: string;
  password: string;
}

interface UserRegistrationRequestByAdmin extends UserRegistrationRequest {
  verified: boolean;
  role: EnumRole;
}

interface UserLoginRequest extends BaseUser {
  password: string;
}

interface User extends BaseUser {
  id: number;
  username: string;
  role: EnumRole;
}

export {
  EnumRole,
  UserRegistrationRequest,
  UserRegistrationRequestByAdmin,
  UserLoginRequest,
  User,
};
