













































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import HomeAnauthenticated from '@/views/Home/HomeAnauthenticated.vue';
import DashboardApi from '@/services/dashboard';
import { DashboardLite } from '@/models/dashboard';
import { User } from '@/models/user';
import store from '@/store';

@Component({
  components: { HomeAnauthenticated },
})
export default class Home extends Vue {
  dashboardApi = new DashboardApi();

  dashboards: DashboardLite[] = [];

  isUserLoggedIn = false;

  @Watch('$store.getters.getUser', { immediate: true })
  onUserAuthenticationStatusChange(user: User | null): void {
    if (user) this.loadDashboards();
  }

  get mdColsNum(): number {
    if (this.dashboards.length <= 3 || this.dashboards.length % 3 === 0) return 4;
    if (this.dashboards.length % 4 === 0) return 3;
    if ((4 - (this.dashboards.length % 4)) > (3 - (this.dashboards.length % 3))) return 4;
    return 3;
  }

  loadDashboards(): void {
    store.dispatch('setLoading', true);
    this.dashboardApi.findAll(false).then((response) => {
      this.dashboards = response.result;
      store.dispatch('setLoading', false);
    });
  }
}
