import Api from './api';
import {
  DashboardConfigurationRequest,
  DashboardLite,
  DashboardConfiguration,
} from '@/models/dashboard';
import { ServerResponse } from '@/models/response';

export default class DashboardApi extends Api {
  public findAll(publicOnly: boolean): Promise<ServerResponse<DashboardLite[]>> {
    const url = `/api/dashboard?publicOnly=${publicOnly}`;

    return this.get<ServerResponse<DashboardLite[]>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public findOne(id: number, encryptedQueries: boolean): Promise<ServerResponse<DashboardConfiguration>> {
    const url = `/api/dashboard/${id}?encryptedQueries=${encryptedQueries}`;

    return this.get<ServerResponse<DashboardConfiguration>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public create(dashboard: DashboardConfigurationRequest): Promise<ServerResponse<null>> {
    const url = '/api/dashboard';

    return this.post<DashboardConfigurationRequest, ServerResponse<null>>(url, dashboard).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public startEditingDashboard(dashboardId: number): Promise<ServerResponse<null>> {
    const url = `/api/dashboard/${dashboardId}/lock`;

    return this.post<null, ServerResponse<null>>(url, null).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public stopEditingDashboard(dashboardId: number): Promise<ServerResponse<null>> {
    const url = `/api/dashboard/${dashboardId}/unlock`;

    return this.post<null, ServerResponse<null>>(url, null).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public update(dashboardId: number, dashboard: DashboardConfiguration): Promise<ServerResponse<null>> {
    const url = `/api/dashboard/${dashboardId}/update`;

    return this.post<DashboardConfiguration, ServerResponse<null>>(url, dashboard).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public remove(dashboardId: number): Promise<ServerResponse<null>> {
    const url = `api/dashboard/${dashboardId}`;

    return this.delete<ServerResponse<null>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }

  // public addChartToDashboard(dashboardId: number, chart: ChartRequest<any>): Promise<ServerResponse<null>> {
  //   const url = `/api/dashboard/${dashboardId}/add-chart`;

  //   return this.post<ChartRequest<any>, ServerResponse<null>>(url, chart).then((response) => {
  //     const { data } = response;

  //     return data;
  //   });
  // }

  // public query(dashboardId: number, query: string): Promise<ServerResponse<any>> {
  //   const url = `/api/dashboard/${dashboardId}/query`;

  //   return this.post<{ query: string }, ServerResponse<any>>(url, { query }).then((response) => {
  //     const { data } = response;

  //     return data;
  //   });
  // }
}
