
































































import { Vue, Component, Watch } from 'vue-property-decorator';
import DashboardApi from '@/services/dashboard';
import { DashboardLite } from '@/models/dashboard';
import store from '@/store';

@Component
export default class HomeAnauthenticated extends Vue {
  dashboardApi = new DashboardApi();

  dashboards: DashboardLite[] = [];

  created(): void {
    this.loadDashboards();
  }

  get mdColsNum(): number {
    if (this.dashboards.length <= 3 || this.dashboards.length % 3 === 0) return 4;
    if (this.dashboards.length % 4 === 0) return 3;
    if ((4 - (this.dashboards.length % 4)) > (3 - (this.dashboards.length % 3))) return 4;
    return 3;
  }

  loadDashboards(): void {
    store.dispatch('setLoading', true);
    this.dashboardApi.findAll(true).then((response) => {
      this.dashboards = response.result;

      store.dispatch('setLoading', false);
    });
  }
}
