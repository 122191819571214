






















import { Vue, Component, Watch } from 'vue-property-decorator';
import AuthApi from '@/services/auth';
import { EnumGlobalModal } from '@/models/global-modals';
import store from '@/store';

@Component({
  components: {},
})
export default class ModalLogin extends Vue {
  authApi = new AuthApi();

  showLocal = false;

  email = '';

  password = '';

  isWrongCredentials = false;

  @Watch('$store.getters.getShownGlobalModal', { immediate: true })
  onShowChange(modal: EnumGlobalModal): void {
    this.showLocal = modal === EnumGlobalModal.LOGIN;
  }

  @Watch('showLocal')
  onShowLocalChange(showLocal: boolean): void {
    if (!showLocal) store.dispatch('setShownGlobalModal', null);
  }

  @Watch('email')
  onEmailChange(): void {
    this.isWrongCredentials = false;
  }

  @Watch('password')
  onPasswordChange(): void {
    this.isWrongCredentials = false;
  }

  onLogIn(): void {
    store.dispatch('setLoading', true);
    this.authApi.login(this.email, this.password).then((response) => {
      if (response.success) {
        store.dispatch('setUser', response.result).then(() => {
          store.dispatch('setShownGlobalModal', null);
        });
      } else {
        console.log('err', response);
        if (response.message.description === 'WRONG_CREDENTIALS') this.isWrongCredentials = true;
      }
      store.dispatch('setLoading', false);
    });
  }
}
