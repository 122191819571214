import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import DashboardApi from '@/services/dashboard';
import { EnumGlobalModal } from '@/models/global-modals';
import { EnumRole } from '@/models/user';
import { hasMinimumRoleRequired } from '@/helpers/authorization';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: { requiresRole: EnumRole.EDITOR },
    children: [
      {
        path: 'sources',
        name: 'SettingsSources',
        component: () => import(/* webpackChunkName: "settingssources" */ '../views/Settings/Sources.vue'),
        meta: { requiresRole: EnumRole.EDITOR },
      },
      {
        path: 'users',
        name: 'SettingsUsers',
        component: () => import(/* webpackChunkName: "settingsusers" */ '../views/Settings/Users.vue'),
        meta: { requiresRole: EnumRole.ADMIN },
      },
    ],
  },
  {
    // todo: remove
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/dashboard/:id',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/dashboard/s/:id',
    name: 'DashboardStandAlone',
    component: () => import(/* webpackChunkName: "dashboardstandalone" */ '../views/Dashboard.vue'),
    meta: { standAlone: true },
  },
  {
    path: '/dashboard/:id/settings',
    name: 'DashboardSettings',
    component: () => import(/* webpackChunkName: "dashboardsettings" */ '../views/DashboardSettings.vue'),
    meta: { requiresLockedDashboard: true, requiresRole: EnumRole.EDITOR },
    children: [
      {
        path: 'general',
        name: 'DashboardSettingsGeneral',
        component: () => import(/* webpackChunkName: "dashboardsettingsgeneral" */ '../views/DashboardSettings/General.vue'),
      },
      {
        path: 'style',
        name: 'DashboardSettingsStyle',
        component: () => import(/* webpackChunkName: "dashboardsettingsstyle" */ '../views/DashboardSettings/Style.vue'),
      },
      {
        path: 'permissions',
        name: 'DashboardSettingsPermissions',
        component: () => import(/* webpackChunkName: "dashboardsettingspermissions" */ '../views/DashboardSettings/Permissions.vue'),
      },
      {
        path: 'variables',
        name: 'DashboardSettingsVariables',
        component: () => import(/* webpackChunkName: "dashboardsettingsvariables" */ '../views/DashboardSettings/Variables.vue'),
        children: [
          {
            path: '',
            name: 'DashboardSettingsVariablesList',
            component: () => import(/* webpackChunkName: "dashboardsettingslistvariables" */ '../views/DashboardSettings/Variables/ListVariables.vue'),
          },
          {
            path: 'add-variable',
            name: 'DashboardSettingsAddVariable',
            component: () => import(/* webpackChunkName: "dashboardsettingsaddvariable" */ '../views/DashboardSettings/Variables/AddVariable.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/dashboard/:id/add-chart',
    name: 'AddChart',
    component: () => import(/* webpackChunkName: "addchart" */ '../views/AddChart.vue'),
    meta: { requiresLockedDashboard: true, requiresRole: EnumRole.EDITOR },
  },
  {
    path: '/dashboard/:id/edit-chart/:panelId',
    name: 'EditChart',
    component: () => import(/* webpackChunkName: "editchart" */ '../views/AddChart.vue'),
    meta: { requiresLockedDashboard: true, requiresRole: EnumRole.EDITOR },
  },
  {
    path: '/create-dashboard',
    name: 'CreateDashboard',
    component: () => import(/* webpackChunkName: "createdashboard" */ '../views/CreateDashboard.vue'),
    meta: { requiresRole: EnumRole.EDITOR },
  },
];

const router = new VueRouter({
  routes,
});

const dashboardApi = new DashboardApi();

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((x) => x.name === 'DashboardSettings') || ['Dashboard', 'AddChart', 'EditChart'].includes(to.name!)) {
    await store.dispatch('resetVariables');
  }

  if (to.matched.some((x) => x.meta.requiresRole && !hasMinimumRoleRequired(store.getters.getUser.role, x.meta.requiresRole))) {
    store.dispatch('setShownGlobalModal', EnumGlobalModal.UNAUTHORIZED);
  } else if (to.matched.some((x) => x.meta.requiresLockedDashboard)) {
    store.dispatch('setLoading', true);
    const dashboardId = parseInt(to.params.id, 10);
    dashboardApi.startEditingDashboard(dashboardId).then((response) => {
      if (response.success) next();
      else { // todo: only in DASHBOARD_IS_LOCKED error message
        next(`/dashboard/${dashboardId}`);
        store.dispatch('setShownGlobalModal', EnumGlobalModal.DASHBOARD_LOCKED);
      }
      store.dispatch('setLoading', false);
    });
  } else {
    next();
  }
});

export default router;
