



















import { Vue, Component, Watch } from 'vue-property-decorator';
import { EnumGlobalModal } from '@/models/global-modals';
import store from '@/store';

@Component({
  components: {},
})
export default class ModalAnauthorized extends Vue {
  showLocal = false;

  @Watch('$store.getters.getShownGlobalModal', { immediate: true })
  onShowChange(modal: EnumGlobalModal): void {
    this.showLocal = modal === EnumGlobalModal.UNAUTHORIZED;
  }

  @Watch('showLocal')
  onShowLocalChange(showLocal: boolean): void {
    if (!showLocal) store.dispatch('setShownGlobalModal', null);
  }
}
