import axios, {
  AxiosRequestConfig, AxiosResponse, AxiosError,
} from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '@/store';

const axiosInstance = axios.create({ withCredentials: true });

const refreshAuthLogic = async () => {
  try {
    await axios.post('/api/auth/refresh-access-token');
  } catch (err) {
    console.log('err');
  }
  return Promise.resolve();
};

const handleError = async <T>(err: AxiosError): Promise<AxiosResponse<T>> => {
  console.log('ERR');

  if (err.response?.status === 401 || err.response?.status === 403) {
    await store.dispatch('setLoading', true);
    await store.dispatch('setUser', null);
    await store.dispatch('setLoading', false);
    // todo important uncomment ? check
    // window.location.href = '/';
  }

  if (err) throw Error;

  return Promise.reject(err);
};

export default class Api {
  protected httpController: AbortController | null = null;

  constructor() {
    createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, { pauseInstanceWhileRefreshing: false });
  }

  getHttpController(): AbortController {
    return new AbortController();
  }

  protected get<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return axiosInstance.get<R>(url, config)
      .catch((err: AxiosError) => handleError(err));
  }

  protected delete<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return axiosInstance.delete<R>(url, config)
      .catch((error: AxiosError) => handleError(error));
  }

  protected post<T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return axiosInstance.post<T, AxiosResponse<R>>(url, data, config)
      .catch((error: AxiosError) => handleError(error));
  }

  protected put<T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return axiosInstance.put<T, AxiosResponse<R>>(url, data, config)
      .catch((error: AxiosError) => handleError(error));
  }
}
