



























import { Vue, Component, Watch } from 'vue-property-decorator';
import Navbar from '@/components/Navbar.vue';
import ModalLogin from '@/components/ModalLogin.vue';
import ModalLockedDashboard from '@/components/ModalLockedDashboard.vue';
import ModalAnauthorized from '@/components/ModalAnauthorized.vue';

@Component({
  components: {
    Navbar,
    ModalLogin,
    ModalLockedDashboard,
    ModalAnauthorized,
  },
})
export default class App extends Vue {
  created(): void {
    console.log('meta', this.$route.meta);
  }

  @Watch('$store.getters.isUserAuthenticated')
  onAuthStateChange(): void {
    // eslint-disable-next-line
    this.$router.push('/').catch(() => {});
  }
}
