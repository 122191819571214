import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import GlobalModals from './modules/global-modals.module';
import Loader from './modules/loader.module';
import User from './modules/user.module';
import Variables from './modules/variables.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    GlobalModals,
    Loader,
    User,
    Variables,
  },
  plugins: [createPersistedState()],
});
