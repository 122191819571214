import { ActionContext } from 'vuex';
import { VariableResults } from '@/models/variable';

interface State {
  variables: VariableResults[];
}

const initialState: State = {
  variables: [],
};

const getters = {
  getVariables: (state: State): VariableResults[] => state.variables,
};

const mutations = {
  setOrUpdateVariable(state: State, variable: VariableResults): void {
    state.variables = state.variables.filter((x) => x.name !== variable.name).concat([variable]);
  },
  resetVariables(state: State): void {
    state.variables = [];
  },
};

const actions = {
  setOrUpdateVariable(context: ActionContext<any, any>, variable: VariableResults): void {
    context.commit('setOrUpdateVariable', variable);
  },
  resetVariables(context: ActionContext<any, any>): void {
    context.commit('resetVariables');
  },
};

export default {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations,
};
