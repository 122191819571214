import { ActionContext } from 'vuex';
import { User } from '@/models/user';

interface State {
  user: User | null;
}

const initialState: State = {
  user: null,
};

const getters = {
  getUser: (state: State): User | null => state.user,
  isUserAuthenticated: (state: State): boolean => !!state.user,
};

const mutations = {
  setUser(state: State, user: User): void {
    state.user = user;
  },
};

const actions = {
  setUser(context: ActionContext<any, any>, user: User | null): void {
    context.commit('setUser', user);
  },
};

export default {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations,
};
