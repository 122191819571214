

























import { Vue, Component } from 'vue-property-decorator';
import AuthApi from '@/services/auth';
import { EnumGlobalModal } from '@/models/global-modals';
import store from '@/store';

@Component({
  components: {},
})
export default class Navbar extends Vue {
  authApi = new AuthApi();

  openLoginModal(): void {
    store.dispatch('setShownGlobalModal', EnumGlobalModal.LOGIN);
  }

  logout(): void {
    this.authApi.logout().then(async (response) => {
      if (response.success) {
        await store.dispatch('setLoading', true);
        await store.dispatch('setUser', null);
        await store.dispatch('setLoading', false);
      } else {
        console.log('err', response);
      }
    });
  }
}
