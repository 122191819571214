import { ActionContext } from 'vuex';
import { User } from '@/models/user';

interface State {
  isLoading: boolean;
}

const initialState: State = {
  isLoading: false,
};

const getters = {
  isLoading: (state: State): boolean => state.isLoading,
};

const mutations = {
  setLoading(state: State, status: boolean): void {
    state.isLoading = status;
  },
};

const actions = {
  setLoading(context: ActionContext<any, any>, status: boolean): void {
    context.commit('setLoading', status);
  },
};

export default {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations,
};
