import { ActionContext } from 'vuex';
import { EnumGlobalModal } from '@/models/global-modals';

interface State {
  globalModalToShow: string | null;
}

const initialState: State = {
  globalModalToShow: null,
};

const getters = {
  getShownGlobalModal: (state: State): string | null => state.globalModalToShow,
};

const mutations = {
  setShownGlobalModal(state: State, globalModalToShow: EnumGlobalModal | null): void {
    state.globalModalToShow = globalModalToShow;
  },
};

const actions = {
  setShownGlobalModal(context: ActionContext<State, State>, modal: EnumGlobalModal | null): void {
    context.commit('setShownGlobalModal', modal);
  },
};

export default {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations,
};
