




















import { Vue, Component, Watch } from 'vue-property-decorator';
import { EnumGlobalModal } from '@/models/global-modals';
import store from '@/store';

@Component({
  components: {},
})
export default class ModalLockedDashboard extends Vue {
  showLocal = false;

  @Watch('$store.getters.getShownGlobalModal', { immediate: true })
  onShowChange(modal: EnumGlobalModal): void {
    this.showLocal = modal === EnumGlobalModal.DASHBOARD_LOCKED;
  }

  @Watch('showLocal')
  onShowLocalChange(showLocal: boolean): void {
    if (!showLocal) store.dispatch('setShownGlobalModal', null);
  }

  goToDashboard(): void {
    this.showLocal = false;

    if (this.$route.name !== 'Dashboard') {
      const dashboardId = parseInt(this.$route.params.id as string, 10);
      this.$router.push(`/dashboard/${dashboardId}`);
    }
  }
}
